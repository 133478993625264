import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDzYM94k73KFsRFB0a4C9GtEnkPBFK-cXY",
  authDomain: "theflyingpanda-e5730.firebaseapp.com",
  projectId: "theflyingpanda-e5730",
  storageBucket: "theflyingpanda-e5730.appspot.com",
  messagingSenderId: "191423585187",
  appId: "1:191423585187:web:6507e66e29c23338959816",
  measurementId: "G-LTDJMX2G36"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
 

export { db, auth };