import './App.css';
import { useState } from 'react';
import { collection, addDoc } from "firebase/firestore";  
import { db } from './firebase';
import Logo from './images/LogoIconColoured.png'

function App() {
(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "olh9546boc");
const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errors, setErrors] = useState({
    email: '',
  });

  // Email validation function using regex
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
    const validateMobile = (mobile) => {
    // This regex checks for a 10-digit mobile number
    //const mobileRegex = /^[0-9]{20}$/;/^[0-9]+$/;
   const mobileRegex = /^[0-9]+$/;
    return mobileRegex.test(mobile);
  };
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Validate email input in real-time
    if (e.target.name === 'email') {
      if (!validateEmail(e.target.value)) {
        setErrors({ ...errors, email: 'Invalid email format' });
      } else {
        setErrors({ ...errors, email: '' });
      }
    }
   // Validate mobile number input in real-time
    if (e.target.name === 'mobile') {
      if (!validateMobile(e.target.value)) {
        setErrors({ ...errors, mobile: 'Invalid mobile number' });
      } else {
        setErrors({ ...errors, mobile: '' });
      }
    }


  };


const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate the email and mobile before form submission
    const isEmailValid = validateEmail(formData.email);
    const isMobileValid = validateMobile(formData.mobile);

    if (!isEmailValid || !isMobileValid) {
      setErrors({
        email: isEmailValid ? '' : 'Please enter a valid email address',
        mobile: isMobileValid ? '' : 'Please enter a valid 10-digit mobile number',
      });
      return;
    }
    try {
      await addDoc(collection(db, "landingPageQuery"), {
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        createddatetime: new Date(),  
        actiontaken :"No"
      });
      setIsSubmitted(true);
      console.log("Document successfully written!");
    } catch (e) {
      console.log("Error adding document: ", e);
    }
    setTimeout(() => {
      setIsSubmitted(false);
    }, 5000);
  };




  return (
 <section className="bg-gray-700 h-screen bg-[url('https://flowbite.s3.amazonaws.com/blocks/marketing-ui/500/background.jpg')] bg-cover bg-center bg-no-repeat bg-blend-multiply">
  <div className="mx-auto max-w-screen-xl px-4 py-8 lg:px-6 lg:py-16 flex flex-col items-center justify-center h-full">
    <div className="max-w-screen-sm text-center">
         <img src={Logo} alt="Logo" className="w-32 h-32 mb-4 mx-auto" />{/* Adjust width and height as needed */} 
      <h1 className="text-4xl font-extrabold tracking-tight text-white md:text-4xl">
        Let us take care of your visa worries, just drop your details down below.
      </h1>
      <form className="mt-8 space-y-4" onSubmit={handleSubmit}>
            <div>
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full px-4 py-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Name"
                required
                value={formData.name}
                onChange={handleChange}
              />
            </div>
              <div>
              <input
                type="email"
                name="email"
                id="email"
                className="block w-full px-4 py-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Your Email"
                required
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p> // Error message if the email is invalid
              )}
            </div>
            
            <div>
              <input
                type="tel"
                name="mobile"
                id="mobile"
                className="block w-full px-4 py-2 text-gray-900 bg-gray-50 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                placeholder="Mobile Number"
                required
                value={formData.mobile}
                onChange={handleChange}
              />
              {errors.mobile && (
                <p className="text-red-500 text-sm mt-1">{errors.mobile}</p>
              )}
            </div>
            <button
              type="submit"
              className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
            >
              Submit
            </button>
            {isSubmitted && (
                    <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded mt-3 text-sm w-full text-center">
                      Sent successfully!
                    </div>
                  )}
          </form>

      <div className="mt-8 flex justify-center space-x-4">
        <button
          className="bg-white text-gray-800 hover:bg-gray-200 transition-colors p-3 rounded-full shadow-lg"
          onClick={() => window.open('https://www.facebook.com/theflyingpanda.io', '_blank')}
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              fillRule="evenodd"
              d="M13.135 6H15V3h-1.865a4.147 4.147 0 0 0-4.142 4.142V9H7v3h2v9.938h3V12h2.021l.592-3H12V6.591A.6.6 0 0 1 12.592 6h.543Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
        <button
          className="bg-white text-gray-800 hover:bg-gray-200 transition-colors p-3 rounded-full shadow-lg"
          onClick={() => window.open('https://www.instagram.com/theflyingpanda.io/', '_blank')}
        >
          <svg
            className="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M3 8a5 5 0 0 1 5-5h8a5 5 0 0 1 5 5v8a5 5 0 0 1-5 5H8a5 5 0 0 1-5-5V8Zm5-3a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h8a3 3 0 0 0 3-3V8a3 3 0 0 0-3-3H8Zm7.597 2.214a1 1 0 0 1 1-1h.01a1 1 0 1 1 0 2h-.01a1 1 0 0 1-1-1ZM12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6Zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0Z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</section>
  );
}


export default App;
